body {
  margin: 0;
  background-color: #000;
  color: #40c824;
  font-family: consolas, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3 {
    font-family: consolas, sans-serif;
}

a {
  color: #40c824;
}

.btn-success {
  background: rgb(21, 136, 17);
}

.btn-success:hover {
  background: #147710;
}

.btn.download {
  color: #40c824;
  border: 1px solid #40c824;
  margin: 8px auto 8px;
  width: 182px;
}

.btn.download:hover {
  color: #000000;
  cursor: pointer;
  background: #40c824;
  box-shadow: 0 0 10px #40c824,
    0 0 15px #40c824,
    0 0 25px #40c824;
  /* transition-delay: 1s; */
}

.bio {
  border: 1px solid #40c824;
}

.profile-pic {
  height: initial !important;
}

.main-section {
    color: #ffffff;
    font-family: consolas, sans-serif;
}

.main-section canvas {
    /* height: 100vh !important; */
}

.main-section__heading {
    background:rgba(0, 0, 0, .6);
    padding: 32px;
}

.main-section__heading h1 {
    margin: 0;
    font-weight: 100;
}

.main-section__heading h3 {
    font-weight: 100;
}

@media (max-width:500px){
    .main-section__heading {
        background: rgba(0, 0, 0, .6);
        width: 85%;
        padding: 16px;
    }
}
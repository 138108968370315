
.card i {
    color: lime !important;
    font-size: 2em;
    margin: 5px;
    transform: translateY(7px);
}

.github-link {
    color: lime !important;
    margin: 0 8px;
    text-decoration: none;
}
.nav {
    font-family: consolas, sans-serif;
    padding: 1em 0;
    background: #000000;
    text-align: center;
}

.nav ul {
    margin: 0;
    padding: 0;
}

.nav ul li {
    list-style-type: none;
    display: inline-block;
}

.nav__link {
    background: transparent;
    border: none;
    text-decoration: none;
    position: relative;
    display: inline-block;
    padding: 12px 24px;
    color: #40c824;
    text-transform: uppercase;
    letter-spacing: 4px;
    font-size: 16px;
    overflow: hidden;
    transition: 0.2s;
}

.nav__link:hover {
    color: #000000;
    cursor: pointer;
    background: #40c824;
    box-shadow: 0 0 10px #40c824,
    0 0 15px #40c824,
    0 0 25px #40c824;
    /* transition-delay: 1s; */
}

@media (max-width:500px) {
    .nav__link{
        padding: 6px 12px;
    }
}

/* .nav__link span {
    position: absolute;
    display: block;
}

.nav__link span:nth-child(1) {
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, transparent, #1DA501);
}

.nav__link:hover span:nth-child(1),
.nav__link:focus span:nth-child(1) {
    left: 100%;
    transition: 1s;
}

.nav__link span:nth-child(2) {
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #1DA501);
}

.nav__link:hover span:nth-child(2),
.nav__link:focus span:nth-child(2) {
    top: 100%;
    transition: 1s;
    transition-delay: 0.25s;
}

.nav__link span:nth-child(3) {
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(270deg, transparent, #1DA501);
}

.nav__link:hover span:nth-child(3),
.nav__link:focus span:nth-child(3) {
    right: 100%;
    transition: 1s;
    transition-delay: 0.5s;
}

.nav__link span:nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #1DA501);
}

.nav__link:hover span:nth-child(4),
.nav__link:focus span:nth-child(4) {
    bottom: 100%;
    transition: 1s;
    transition-delay: 0.75s;
} */